import { useAsync, useAsyncFn } from 'react-use';
import { Region, ServiceInfo } from '../types/types';
import { getServiceDataSources } from 'provisioning/datasource';
import { getAccounts, getAllDataSources, getConfig, getRegions } from 'api';

const getDataSources = async (service: string) => {
  const allDataSources = await getAllDataSources();
  return getServiceDataSources(service, allDataSources);
};

export const useData = (service: ServiceInfo) => {
  const { value: accounts, loading: accountsLoading } = useAsync(getAccounts);
  const { value: regions, loading: regionsLoading } = useAsync<() => Promise<Region[]>>(
    () => getRegions(service.id),
    [service.id]
  );

  let { value: installedDataSources, loading: dataSourcesLoading } = useAsync(
    () => getDataSources(service.id),
    [service.id]
  );

  const [getDataSourcesState, fetchDataSources] = useAsyncFn(() => getDataSources(service.id), [service.id]);
  if (getDataSourcesState.value) {
    installedDataSources = getDataSourcesState.value;
  }

  const { value: appConfig, loading: configLoading } = useAsync(() => getConfig(), []);

  const dataLoading =
    accountsLoading || regionsLoading || dataSourcesLoading || getDataSourcesState.loading || configLoading;

  return { dataLoading, regions, accounts, appConfig, installedDataSources, fetchDataSources };
};
