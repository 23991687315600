import { useAsync } from 'react-use';
import { getBackendSrv } from '@grafana/runtime';
import { AWSAccount, Region, ServiceInfo } from '../types/types';
import { API_ROOT } from '../constants';

const backendSrv = getBackendSrv();

const getResources = (
  singleAccount: boolean,
  serviceId: ServiceInfo['id'],
  regionsStr: string,
  accountsStr: string
) => {
  if (regionsStr.length <= 0 || (accountsStr.length <= 0 && !singleAccount)) {
    return Promise.resolve([]);
  }

  return backendSrv.get(`${API_ROOT}/services/${serviceId}/resources?regions=${regionsStr}&accounts=${accountsStr}`);
};

export const useResources = (
  singleAccount: boolean,
  serviceId: ServiceInfo['id'],
  regionIds: Array<Region['id']>,
  accountIds: Array<AWSAccount['id']>
) => {
  const regionsStr = regionIds.join(',');
  const accountsStr = accountIds.join(',');

  const { value: resources, loading: resourcesLoading } = useAsync(
    () => getResources(singleAccount, serviceId, regionsStr, accountsStr),
    [singleAccount, serviceId, regionsStr, accountsStr]
  );

  return { resourcesLoading, resources };
};
