import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { Button, InfoBox, useStyles } from '@grafana/ui';
import { useElasticDataSources } from '../../hooks';
import { migrate } from '../../provisioning/migration';

export const MigrationBox = () => {
  const styles = useStyles(getStyles);

  const { dataSources } = useElasticDataSources();

  const migrateDataSources = async () => {
    await migrate(dataSources ?? []);
    window.location.reload();
  };

  return (
    <div>
      {(dataSources ?? []).length > 0 && (
        <div className={styles.serviceSection}>
          <InfoBox>
            <h3>OpenSearch</h3>
            <h5 className={styles.migrationText}>
              We have identified data sources in your workspace that are currently provisioned using the Open Distro for
              Elasticsearch data source plugin. We recommend migrating these data sources to the new Amazon OpenSearch
              data source plugin, successor to the Amazon Elasticsearch Service and Open Distro for Elasticsearch
              plugins. This ensures that you will continue to receive the latest feature updates via the OpenSearch
              plugin.
              <br />
              <strong>Click the button below to perform the migration.</strong>
            </h5>
            <div className="gf-form-button-row">
              <Button variant="primary" onClick={migrateDataSources}>
                Migrate
              </Button>
            </div>
          </InfoBox>
        </div>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    serviceSection: css`
      margin-top: ${theme.spacing.xl};
      margin-bottom: ${theme.spacing.xl};
    `,
    migrationText: css`
      line-height: ${theme.typography.lineHeight.md};
      color: ${theme.colors.textWeak};
    `,
  };
};
